<template>
  <div>
    <v-form v-model="valid" ref="form" lazy-validation>
      <card-title title="Osnovni podaci" icon="information" />
      <v-card class="pa-4 mb-8 mt-1">
        <translation-tabs
          @select="activeTitleLanguage = $event"
          :missingTranslations="getMissingTranslations(propertyData.title)" />
        <v-row dense class="mt-2">
          <v-col cols="12" md="12">
            <v-text-field
              v-model="propertyData.title[activeTitleLanguage]"
              :rules="[v => !!v || requiredMessage]"
              autocomplete="off"
              class="font-weight-bold"
              clearable
              label="Naziv"
              required />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-btn
            @click="translate('title')"
            :disabled="isDisabledTitleTranslation"
            :loading="isLoadingTitleTranslation"
            class="mb-2"
            text>
            Dohvati prijevod za {{ activeTitleLanguageFull }}
            <v-icon right>mdi-web</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              v-model="propertyData.category"
              :items="categories"
              :rules="[v => !!v || requiredMessage]"
              item-text="label"
              label="Kategorija" />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="propertyData.propertyType"
              :items="propertyTypes"
              :disabled="!propertyData.category"
              :rules="[v => !!v || requiredMessage]"
              item-text="label"
              label="Tip nekretnine" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <vuetify-money
              v-model="propertyData.price"
              label="Cijena"
              class="font-weight-bold"
              :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
          </v-col>
          <v-col cols="12" md="2">
            <vuetify-money
              v-model="propertyData.actualPrice"
              label="Stvarna cijena"
              :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
          </v-col>
          <v-col cols="12" md="2">
            <vuetify-money
              v-model="propertyData.priceM2"
              label="Cijena/m²"
              :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
          </v-col>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="propertyData.evaluatedPrice"
              label="Procjena nekretnine (službena)"
              :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="propertyData.taxStatus"
              @input="validateNull('taxStatus')"
              :items="taxTypes"
              clearable
              item-text="label"
              label="Porezni status" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3" class="d-flex align-center">
            <v-checkbox
              v-model="propertyData.priceOnRequest"
              color="lime darken-4"
              class="input-lime-darken-4 mr-2"
              label="Cijena na upit" />
            <info-tooltip
              color="lime darken-4"
              text="Prikaži/sakrij informaciju o cijeni" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <translation-tabs
              @select="activeShortDescriptionLanguage = $event"
              :missingTranslations="getMissingTranslations(propertyData.shortDescription)"
              class="mb-2" />
            <text-editor
              v-model="propertyData.shortDescription[activeShortDescriptionLanguage]"
              placeholder="Kratki opis (javno dostupno svim korisnicima)" />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-btn
            @click="translate('shortDescription')"
            :disabled="isDisabledShortDescriptionTranslation"
            :loading="isLoadingShortDescriptionTranslation"
            class="mb-2"
            text>
            Dohvati prijevod za {{ activeShortDescriptionLanguageFull }}
            <v-icon right>mdi-web</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-col>
            <translation-tabs
              @select="activeDescriptionLanguage = $event"
              :missingTranslations="getMissingTranslations(propertyData.description)"
              class="mb-2" />
            <text-editor
              v-model="propertyData.description[activeDescriptionLanguage]"
              placeholder="Detaljan opis (dostupno samo korisnicima sa punim pristupom)" />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end">
          <v-btn
            @click="translate('description')"
            :disabled="isDisabledDescriptionTranslation"
            :loading="isLoadingDescriptionTranslation"
            class="mb-2"
            text>
            Dohvati prijevod za {{ activeDescriptionLanguageFull }}
            <v-icon right>mdi-web</v-icon>
          </v-btn>
        </v-row>
      </v-card>

      <card-title title="Lokacija i površina" icon="map-marker" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row class="mt-2">
          <v-col cols="12" md="4">
            <v-select
              v-model="propertyData.countryId"
              :items="countries"
              :rules="[v => !!v || requiredMessage]"
              item-text="label"
              item-value="id"
              label="Država" />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="propertyData.regionId"
              @input="onRegionChange"
              :items="regions"
              clearable
              item-text="name"
              item-value="id"
              label="Regija" />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="propertyData.islandId"
              @input="validateNull('islandId')"
              :items="islands"
              clearable
              item-text="name"
              item-value="id"
              label="Otok" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              v-model="propertyData.countyId"
              @input="onCountyChange"
              :items="counties"
              clearable
              item-text="name"
              item-value="id"
              label="Županija" />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="propertyData.municipalityId"
              @input="onMunicipalityChange"
              :disabled="!propertyData.countyId"
              :items="municipalities"
              clearable
              item-text="name"
              item-value="id"
              label="Općina" />
          </v-col>
          <v-col cols="12" md="4">
           <v-select
              v-model="propertyData.cityId"
              @input="validateNull('cityId')"
              :disabled="!propertyData.municipalityId"
              :items="cities"
              clearable
              item-text="name"
              item-value="id"
              label="Mjesto" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="propertyData.quarter"
              :disabled="!propertyData.cityId"
              label="Uža lokacija" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="propertyData.address"
              :disabled="!propertyData.quarter"
              label="Adresa" />
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="propertyData.surface"
              :class="{ 'font-weight-bold': !isLandPlot }"
              label="Površina objekta"
              :options="{ locale: 'us-US', precision: 0, suffix: 'm²' }" />
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="propertyData.surfaceType"
              @input="validateNull('surfaceType')"
              :items="surfaceTypes"
              clearable
              item-text="label"
              label="Brutto/Netto" />
          </v-col>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="propertyData.plotSurface"
              :class="{ 'font-weight-bold': isLandPlot }"
              label="Površina zemljišta"
              :options="{ locale: 'us-US', precision: 0, suffix: 'm²' }" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="propertyData.seaDistance"
              label="Udaljenost od mora"
              :options="{ locale: 'us-US', precision: 0, suffix: 'm' }" />
          </v-col>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="propertyData.beachDistance"
              label="Udaljenost od plaže"
              :options="{ locale: 'us-US', precision: 0, suffix: 'm' }" />
          </v-col>
          <v-col cols="12" md="3">
            <vuetify-money
              v-model="propertyData.cityCentreDistance"
              label="Udaljenost od centra"
              :options="{ locale: 'us-US', precision: 0, suffix: 'm' }" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.seafront"
              label="Prvi red do mora" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.seaView"
              label="Pogled na more" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.panoramicView"
              label="Panoramski pogled" />
          </v-col>
        </v-row>
      </v-card>
      
      <card-title title="Lokacija na karti" icon="map-marker" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="propertyData.coordinates.lat"
              disabled
              label="Koordinate (LAT)" />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="propertyData.coordinates.lng"
              disabled
              label="Koordinate (LNG)" />
          </v-col>

          <v-col cols="12" md="3">
            <v-btn @click="showGoogleMapDialog = true" width="100%">
              {{ hasCoordinates ? 'Uredi' : 'Označi' }} lokaciju na karti
              <v-icon class="ml-2" size="20" color="success">mdi-map-marker</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              @click="removeCoordinates"
              :disabled="!hasCoordinates"
              class="mr-3"
              width="100%">
              Ukloni lokaciju
              <v-icon class="ml-2" size="20" color="error">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <google-map-dialog
        v-if="showGoogleMapDialog"
        v-model="showGoogleMapDialog"
        @updateCoordinates="updateCoordinates"
        :initialMarkerPosition="initialMarkerPosition" />

      <template v-if="!isLandPlot">
        <card-title title="Informacije o objektu" icon="home" />
        <v-card class="pa-4 mb-8 mt-1">
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model.number="propertyData.floor" label="Kat" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model.number="propertyData.floorsTotal"
                label="Ukupno katova"
                type="number" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="propertyData.newConstruction"
                label="Novogradnja" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model.number="propertyData.rooms"
                label="Broj prostorija"
                type="number" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model.number="propertyData.bedrooms"
                label="Broj spavaćih soba"
                type="number" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model.number="propertyData.bathrooms"
                label="Broj kupaona"
                type="number" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model.number="propertyData.toilets"
                label="Broj toaleta"
                type="number" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-combobox
                v-model.number="propertyData.constructionYear"
                :items="years"
                label="Godina izgradnje"
                type="number" />
            </v-col>
            <v-col cols="12" md="3">
              <datepicker
                v-model="propertyData.constructionDeadline"
                placeholder="Rok završetka" />
            </v-col>
            <v-col cols="12" md="3">
              <v-combobox
                v-model.number="propertyData.adaptationYear"
                :items="years"
                label="Godina adaptacije"
                type="number" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="propertyData.condition"
                @input="validateNull('condition')"
                :items="conditions"
                item-text="label"
                clearable
                label="Stanje" />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="propertyData.energyCertificate"
                @input="validateNull('energyCertificate')"
                :items="['A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G']"
                clearable
                label="Energetska učinkovitost (EC)" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.heating" label="Grijanje" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.cooling" label="Hlađenje" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.parking" label="Parking" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="propertyData.garageParking" label="Garažno mjesto" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.elevator" label="Lift" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.swimmingPool" label="Bazen" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.gym" label="Teretana" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.jacuzzi" label="Jacuzzi" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox v-model="propertyData.sauna" label="Sauna" />
            </v-col>
          </v-row>
        </v-card>

        <card-title title="Ostale informacije" icon="text-box-outline" />
        <v-card class="pa-4 mb-8 mt-1">
          <v-row>
            <v-col cols="12" md="3">
              <vuetify-money
                v-model="propertyData.mortgage"
                label="Otkup/hipoteka"
                :options="{ locale: 'us-US', precision: 0, suffix: '€' }" />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="propertyData.transferRiskLevel"
                @input="validateNull('transferRiskLevel')"
                :items="transferRiskLevels"
                clearable
                item-text="label"
                label="Stupanj rizičnosti transkacije" />
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="propertyData.possession" label="Posjed nekretnine" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="propertyData.orientation"
                @input="validateNull('orientation')"
                :items="orientations"
                clearable
                multiple
                item-text="label"
                label="Orijentacija" />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="propertyData.infrastructure"
                @input="validateNull('infrastructure')"
                :items="infrastructure"
                clearable
                multiple
                item-text="label"
                label="Osnovna infrastruktura" />
            </v-col>
          </v-row>
        </v-card>
      </template>

      <card-title title="Partner i Agent" icon="account-circle" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="propertyData.partnerId"
              @input="validateNull('partnerId')"
              :items="partners"
              clearable
              item-text="name"
              item-value="id"
              label="Partner" />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="propertyData.agentId"
              @input="validateNull('agentId')"
              :items="agents"
              clearable
              item-value="id"
              label="Agent">
              <template slot="selection" slot-scope="data">
                {{ data.item.firstName }} {{ data.item.lastName }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.firstName }} {{ data.item.lastName }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <card-title title="Dozvole" icon="check-decagram" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.buildingPermit"
              label="Građevinska dozvola" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.locationPermit"
              label="Lokacijska dozvola" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.usagePermit"
              label="Uporabna dozvola" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.derivedConditionSolution"
              label="Rješenje o izvedenom stanju" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.ownershipCertificate"
              label="Vlasnički list" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.condominium"
              label="Etažirano" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.condominiumInProcess"
              label="Etažiranje u postupku" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.usagePermitInProcess"
              label="Uporabna dozvola u postupku" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.bookOfDepositedContracts"
              label="Knjiga položenih ugovora" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.minimumTechnicalRequirements"
              label="Minimalni tehnički uvjeti" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.legalizationInProcess"
              label="Legalizacija u postupku" />
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="propertyData.possessionInProcess"
              label="Posjed u postupku" />
          </v-col>
        </v-row>
      </v-card>

      <card-title title="Katastar i zemljišnik" icon="text-box-search" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="propertyData.landRegistryExtract"
              label="Broj ZK uloška" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="propertyData.cadastreMunicipality"
              label="Katastarska općina" />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="propertyData.cadastreParcelNumber"
              label="Broj katastarske čestice" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="propertyData.externalLinks.katastarHr"
              label="Poveznica katastar.hr"
              prepend-icon="mdi-link-variant" />
          </v-col>
        </v-row>
      </v-card>

      <card-title title="Fotografije" icon="image-multiple" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12">
            <file-upload
              @showWarning="showPhotoUploadWarning = $event"
              @uploaded="storePhoto"
              :inputId="'upload-photo'"
              :maxSize="10"
              :propertyId="this.propertyId"
              label="Dovucite fotografije ovdje" />
          </v-col>
        </v-row>
        <property-gallery v-model="propertyData.photos" />
      </v-card>

      <card-title title="Video" icon="video" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="propertyData.video"
              clearable
              label="Video URL (Youtube)"
              prepend-icon="mdi-link" />
          </v-col>
        </v-row>
        <video-player
          v-if="propertyData.video"
          :videoUrl="propertyData.video" />
      </v-card>

      <card-title title="Virtualna šetnja" icon="virtual-reality" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="propertyData.virtualTour"
              clearable
              label="Virtual Tour URL"
              prepend-icon="mdi-link" />
          </v-col>
        </v-row>
        <virtual-tour
          v-if="propertyData.virtualTour"
          :tourUrl="propertyData.virtualTour" />
      </v-card>

      <card-title title="Ugovori" icon="file-multiple" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col cols="12" md="6">
            <contract-upload
              v-model="propertyData.contracts.mediationAgreement"
              @delete="setContract('mediationAgreement', null)"
              @uploaded="setContract('mediationAgreement', $event)"
              :label="'Ugovor o posredovanju'"
              :propertyId="propertyId" />
          </v-col>
          <v-col cols="12" md="6">
            <contract-upload
              v-model="propertyData.contracts.NDA"
              @delete="setContract('NDA', null)"
              @uploaded="setContract('NDA', $event)"
              :label="'NDA'"
              :propertyId="propertyId" />
          </v-col>
        </v-row>
      </v-card>

      <card-title title="Ostali dokumenti" icon="file-table-box-multiple" />
      <v-card class="pa-4 mb-8 mt-1">
        <v-row>
          <v-col>
            <file-upload
              @showWarning="showDocumentUploadWarning = $event"
              @uploaded="storeDocument"
              :propertyId="this.propertyId"
              :maxSize="10"
              :inputId="'upload-document'"
              accept="DOCUMENTS"
              label="Dovucite dokumente ovdje" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <other-documents v-model="propertyData.documents" />
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-col cols="6" md="6">
          <v-select
            v-model="propertyData.published"
            :items="publishedOptions"
            item-text="label"
            label="Objavi na portalu" />
        </v-col>
        <v-col cols="6" md="6">
          <v-select
            v-model="propertyData.featured"
            :items="featuredOptions"
            item-text="label"
            label="Istaknuto" />
        </v-col>
      </v-row>
      
      <v-row dense>
        <v-col class="text-right">
          <div
            v-if="showPhotoUploadWarning"
            class="mb-2 red--text text--darken-2 text-caption">
            Upozorenje: Odabrali ste fotografije koje niste uploadali!
          </div>
          <div
            v-if="showDocumentUploadWarning"
            class="mb-2 red--text text--darken-2 text-caption">
            Upozorenje: Odabrali ste dokumente koje niste uploadali!
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col :class="{ 'text-center': isScreenXs }">
          <v-btn
            v-if="propertyData.id"
            @click="showDeleteDialog = true"
            class="px-10"
            color="red darken-2"
            dark>
            Arhiviraj nekretninu
          </v-btn>
          <delete-modal
            v-if="propertyData.id"
            @cancel="showDeleteDialog = false"
            @confirm="archive"
            :show="showDeleteDialog"
            :title="`Arhiviraj nekretninu ${property.publicId}`"
            confirmLabel="Arhiviraj"
            hideDeleteIcon
            subtitle="Jeste li sigurni da želite arhivirati ovu nekretninu?" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :class="`flex-${itemsDirection}`"
          class="d-flex align-center justify-end">
          <span v-if="property" class="caption mr-4 mb-1 grey--text">
            Zadnje promjene: {{ new Date(property.updatedAt) | formatDate }}
          </span>
          <v-btn
            :disabled="isDisabledSave"
            class="px-10"
            color="success"
            @click="save"
            large>
            {{ propertyData.id ? 'Spremi promjene' : 'Dodaj nekretninu' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import api from '../../../api/property';
import CardTitle from './common/CardTitle';
import category from 'common/property/category';
import ContractUpload from './documents/ContractUpload';
import Datepicker from '../../common/Datepicker';
import DeleteModal from '../../common/DeleteModal';
import FileUpload from '../../common/FileUpload';
import get from 'lodash/get';
import GoogleMapDialog from './GoogleMapDialog';
import InfoTooltip from './common/InfoTooltip';
import isEqual from 'lodash/isEqual';
import language from 'common/language';
import OtherDocuments from './documents/OtherDocuments';
import PropertyGallery from './Gallery';
import { propertyMixin } from './property-mixin';
import propertyType from 'common/property/type';
import pull from 'lodash/pull';
import reduce from 'lodash/reduce';
import reverse from 'lodash/reverse';
import range from 'lodash/range';
import TextEditor from '../../common/TextEditor';
import TranslationTabs from '../../common/TranslationTabs';
import VideoPlayer from './VideoPlayer';
import VirtualTour from './VirtualTour';
import Vue from 'vue';

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export default {
  name: 'property-section',
  mixins: [propertyMixin],
  props: {
    propertyId: { type: Number, default: null }
  },
  data: () => {
    return {
      activeTitleLanguage: 'HR',
      activeDescriptionLanguage: 'HR',
      activeShortDescriptionLanguage: 'HR',
      constructionDeadlineCalendar: false,
      isLoadingDescriptionTranslation: false,
      isLoadingShortDescriptionTranslation: false,
      isLoadingTitleTranslation: false,
      showDeleteDialog: false,
      showDocumentUploadWarning: false,
      showGoogleMapDialog: false,
      showPhotoUploadWarning: false,
      valid: true
    }
  },
  computed: {
    ...mapGetters('translations', [
      'categories',
      'commercial',
      'conditions',
      'countries',
      'infrastructure',
      'orientations',
      'residential',
      'surfaceTypes',
      'transferRiskLevels',
      'taxTypes'
    ]),
    ...mapState('properties', ['property']),
    activeDescriptionLanguageFull() {
      return language[this.activeDescriptionLanguage];
    },
    activeShortDescriptionLanguageFull() {
      return language[this.activeShortDescriptionLanguage];
    },
    activeTitleLanguageFull() {
      return language[this.activeTitleLanguage];
    },
    hasChanged() {
      return !isEqual(this.propertyData, this.initialPropertyData);
    },
    hasCoordinates() {
      return !!this.propertyData.coordinates &&
        !!this.propertyData.coordinates.lat &&
        !!this.propertyData.coordinates.lng;
    },
    initialMarkerPosition() {
      return get(this.propertyData, 'coordinates');
    },
    isDisabledDescriptionTranslation() {
      return this.activeDescriptionLanguage === 'HR' ||
        !!this.propertyData.description[this.activeDescriptionLanguage]
    },
    isDisabledShortDescriptionTranslation() {
      return this.activeShortDescriptionLanguage === 'HR' ||
        !!this.propertyData.shortDescription[this.activeShortDescriptionLanguage]
    },
    isDisabledSave() {
      return !this.valid || !this.hasChanged || this.showPhotoUploadWarning ||
        this.showDocumentUploadWarning;
    },
    isDisabledTitleTranslation() {
      return this.activeTitleLanguage === 'HR' ||
        !!this.propertyData.title[this.activeTitleLanguage]
    },
    isLandPlot() {
      const { COMMERCIAL_LAND_PLOT, LAND_PLOT } = propertyType;
      return this.propertyData.propertyType === COMMERCIAL_LAND_PLOT.value
        || this.propertyData.propertyType === LAND_PLOT.value
    },
    isScreenXs() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    itemsDirection() {
      return this.isScreenXs ? 'column' : 'row';
    },
    propertyTypes() {
      return this.propertyData.category === category.COMMERCIAL.value
        ? this.commercial
        : this.residential
    },
    publishedOptions() {
      return [
        { label: 'Sakriveno', value: false },
        { label: 'Objavljeno', value: true }
      ];
    },
    featuredOptions() {
      return [
        { label: 'Ne', value: false },
        { label: 'Da', value: true }
      ];
    },
    requiredMessage() {
      return 'Ovo polje je obavezno';
    },
    years() {
      const years = range(1950, new Date().getFullYear() + 1);
      return reverse(years);
    }
  },
  methods: {
    ...mapActions('properties', ['create', 'update']),
    async addProperty() {
      const property = await this.create(this.propertyData);
      const params = { propertyId: property.id };
      this.$router.push({ name: 'edit-property', params });
    },
    archive() {
      return api.remove(this.property.id)
        .then(() => this.$router.push({ name: 'home' }));
    },
    getMissingTranslations(field) {
      const languages = Object.keys(language);
      const translations = reduce(field, (acc, value, key) => {
        if (value) acc.push(key)
        return acc;
      }, []);
      const missing = pull(languages, ...translations);
      return missing;
    },
    removeCoordinates() {
      this.propertyData.coordinates.lat = null;
      this.propertyData.coordinates.lng = null;
    },
    async save() {
      this.$refs.form.validate();
      this.$nextTick(async () => {
        if (!this.valid) return this.scrollToError();
        return this.propertyId ? this.updateProperty() : this.addProperty();
      });
    },
    scrollToError() {
      const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
      this.$vuetify.goTo(el, { offset: 50 });
    },
    setContract(contractName, data) {
      this.propertyData.contracts[contractName] = data;
    },
    storeDocument(data) {
      this.propertyData.documents.push(data);
    },
    storePhoto(data) {
      this.propertyData.photos.push(data);
    },
    async translate(field) {
      const capitalized = capitalize(field);
      this[`isLoading${capitalized}Translation`] = true;
      const lang = this[`active${capitalized}Language`] === 'SI'
        ? 'sl' : this[`active${capitalized}Language`].toLowerCase();
      const params = {
        Text: this.propertyData[field].HR,
        SourceLanguageCode: 'hr',
        TargetLanguageCode: lang
      }
      const { TranslatedText: translation } = await api.getTranslation(params);
      const activeLang = this[`active${capitalized}Language`];
      Vue.set(this.propertyData[field], activeLang, translation);
      this[`isLoading${capitalized}Translation`] = false;
    },
    updateCoordinates(coordinates) {
      this.propertyData.coordinates = coordinates;
    },
    async updateProperty() {
      await this.update(this.propertyData);
    }
  },
  watch: {
    isLandPlot(val) {
      // TODO: Reset all buidling fields
      if (!val) {
        this.rooms = null;
      }
    },
    activeDescriptionLanguage: {
      immediate: true,
      handler(lang) {
        if (!this.propertyData.description[lang]) {
          Vue.set(this.propertyData.description, lang, '');
        }
      }
    },
    activeShortDescriptionLanguage: {
      immediate: true,
      handler(lang) {
        if (!this.propertyData.shortDescription[lang]) {
          Vue.set(this.propertyData.shortDescription, lang, '');
        }
      }
    }
  },
  components: {
    CardTitle,
    ContractUpload,
    Datepicker,
    DeleteModal,
    FileUpload,
    GoogleMapDialog,
    InfoTooltip,
    OtherDocuments,
    PropertyGallery,
    TextEditor,
    TranslationTabs,
    VideoPlayer,
    VirtualTour
  }
}
</script>

<style scoped lang="scss">
::v-deep .input-lime-darken-4 {
  label, .v-icon {
    color: #827717;
  }
}

::v-deep {
  .ck-content.ck-editor__editable {
    min-height: 250px;
  }
}
</style>
